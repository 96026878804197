
.header-top-section {
    
    
    .navbar-brand>img {
        width: 100px;
    }    
    .logo-hidden {
        display: none;
    }
    .navbar-nav {
       
        .nav-link {
            padding: 8px 16px;
            color: $mainTextColorRevers;
            
            @media only screen and (min-width: 992px) and (max-width: 1200px) {
                padding: 8px;
            }

            &:hover{
                  color: $primary;
            }
        }

        .nav-link.active span{
            border-bottom:2px solid $primary;
       }
       
    }
   
    &.bgauto{
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
        position: fixed;
        z-index: 99;
        background:$mainTextColorRevers;
        .logo-hidden {
            display: inline-block;
        }
        .logo-white {
            display: none;
        }
        .navbar-nav {
            a {
                color: $mainTextColor;
            }
        }
        .bar1,
        .bar2,
        .bar3 {
            background-color:  $mainTextColor;
        }
    }
}

.navbar-toggler {
    &:focus {
        outline: none;
    }
}
.header-top-section {
    .navbar-brand{
        color: white;
        font-size:14px;
        font-weight: 300;
        line-height:28px;

        i{
        
            text-decoration: none;
            border-left: 1px solid white;
        }
    }
}
.header-top-section.bgauto {
    .navbar-brand{
        color: black;
    }
}
/* Hamburger Menu */
.bar1,
.bar2,
.bar3 {
    width           : 32px;
    height          : 3px;
    background-color: $mainTextColorRevers;
    margin          : 6px 0;
    transition      : 0.4s;
    display         : block;
}

[aria-expanded="true"] .bar1 {
    -webkit-transform: rotate(-45deg) translate(-4px, 4px);
    transform        : rotate(-45deg) translate(-4px, 4px);
}

[aria-expanded="true"] .bar2 {
    opacity: 0;
}

[aria-expanded="true"] .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -9px);
    transform        : rotate(45deg) translate(-8px, -9px);
}