/*Blog Banner*/
.blog-header{
    height:400px;
    position: relative;
    background: url("../images/blog-bg.jpg") no-repeat left top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.blog-info{
  width: 80%;
  margin: auto;
  font-size:54px;
  line-height:60px;
  font-weight:300;
  text-align: center;
  color: $white20;
  position: relative;
  z-index: 2;
}

.blog-header-bg{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:#000;
  opacity: .9;
}

@media (max-width:640px) {
  .intro{
    height:350px;
  }
  .intro-info{
    padding-top: 30px;
    font-size:32px;
    line-height:42px;
  
    span{
        font-weight: 700;
        color:$mainTextColorRevers;
    }
  }
}