/*Works*/
.works{
    background:$gray80;
    color: $white;
    overflow:hidden;

    h4{
        color: $white;
    }
}

.portfolio-panel {
    padding: 0 5%;
    overflow:hidden;

    // .swiper-container {
    //     padding: 10px 0;
    // }

    @media (max-width: 1200px) {
        padding: 0 0;
    }

    .portfolio {
        a {
            figure {
                img {
                    width        : 100%;
                    height       : 100%;
                    border-radius: 4px;
                    box-shadow   : 0 0 10px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    transition   : all 400ms ease-in-out;
                }

                figcaption {
                    text-align: center;
                    font-size : 18px;
                    margin-top: 10px;
                    color     : $white;
                }                
            }
            &:hover {
                text-decoration: none;
                img {
                    transform: translateY(-10px);
                }
            }
        }
    }
}


.swiper-button-prev,
.swiper-button-next {
    position         : absolute;
    top              : 40%;
    width            : 40px;
    height           : 40px;
    z-index          : 1;
    border:1px solid $white;
    background       :$white;
    text-align: center;
    line-height: 40px;
    cursor: pointer;

    &:hover{
        text-decoration: none;
        border:1px solid $primary;
    }

    img{
        width: 16px;
    }
}

.swiper-button-prev {
    left: 0;
    border-left: none;
}

.swiper-button-next {
    right: 0;
}
@media (max-width:575px) {
    .swiper-button-prev,
    .swiper-button-next {
        top              : 35%;
    }
}