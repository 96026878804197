/*Offering*/
.offering{
    background:$gray10;
/* Expertise */
.expertise{
   
    .expertise-types{
        width: 95%;

        .expertise-title-number{
            margin-bottom:0px;
            color: $gray80;
        }
        .expertise-title-name{
            font-size:18px;
            color: $black;
            font-weight: 500;
        }
    }

}

    /* Services */
    .services{
        .dev-services{
            background: $white;
            border-radius:2px;
            border-bottom: 2px solid $primary;
            box-shadow: 0 0 3px rgba(0,0,0, .2);
            padding:20px;
            min-height: 300px;
            text-align: center;
        
            h6{
                margin-bottom: 12px;
            }

            .dev-services-icons{
                margin-bottom: 15px;

                img{
                    width: 48px;
                }
            }
           
        }
}

}




@media (max-width:640px) {
    .offering{
        .expertise{
   
            .expertise-types{
                width:100%;
            }
        .expertise-types{
            // padding: 20px 0;
        }
        }

    .services{
        .dev-services{
            min-height:auto;        
            h6{
                margin-bottom: 12px;
            }

            .dev-services-icons{
                margin-bottom: 15px;
            }
        }
}
    }
}
