
h1,h2,h3,h4,h5,h6{
    font-weight:300;
    color:$black;
}

h1{
    font-size:60px !important;
    line-height:90px;
}
h2{
    font-size:40px;
    line-height:60px;
}
h3{
    font-size:36px;
    line-height:54px;
}
h4{
    font-size:30px;
    line-height:45px;
}
h5{
    font-size:24px;
    line-height: 30px;
}
h6{
    font-size:18px;
    line-height:28px;
}

@media (max-width:640px) {
    h1.intro-info {
        font-size:32px !important;
        line-height:42px;
    }
}