*{
    margin: 0;
    padding: 0;
}
*, ::after, ::before {
    box-sizing: border-box;
}
p:last-child{
    margin-bottom: 0;
}
body {
    // font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    color: $mainTextColor;
    font-size: 14px;
    line-height:22px;
    position: relative; 
}







