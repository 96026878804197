/*Who We Are Section */
.about-contact{
    display: flex;
    align-items: stretch;
    justify-content: center;

    .accol{
    flex: 1;
    display: flex;
    align-items: center;
}
}


.whowe{
    background: url("../images/thinkdesign.jpg") no-repeat left top;
    background-size: cover;
    position: relative;

    .whowe-info-section{
        padding: 60px;
        text-align: center;
        position: relative;
        z-index: 2;
        h4{
          color: $white;
      }
      h6{
          color: $white;
      }
    }

    .whowe-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:$primary;
        opacity: .8;
      }
  }

 

  .cform{
    padding: 60px;
  }


  @media (max-width:575px) {
    .about-contact{
        display: block;
    }

    .whowe{    
        .whowe-info-section{
            padding:30px;
        }
      }
    .cform{
        padding:30px;
      }
}