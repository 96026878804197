// $mainTextColor: #212529;
$mainTextColor: #6f6f6f;

$mainTextColorRevers: #ffffff;
$primary: #ff214f;
/* Gray*/
$gray10: #f7f7f7;
$gray80: #1c1c1c;




/*Other Colors*/
$black:#000000;
$white:#ffffff;
$white20:#cbcaca;

/* Padding */
$sectionSpacingForDesktop:60px;
$sectionSpacingForMoile:30px;