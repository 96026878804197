/**/


.v-proposition{
    background: url("../images/Value-Proposition.jpg") no-repeat center center;
    background-size: cover;
    position: relative;
  
    color: $white;

    .v-proposition-info{
        position: relative;
        padding: 60px 0;
        font-size: 18px;
        line-height:28px;
        font-weight: 300;
        z-index: 2;
        h4{
          color: $white;
      }
    
    }

    .v-proposition-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:$black;
        opacity: .8;
      }
  }
.valueproposition{
    background:$gray10;
    .our-value{
        .container {
            max-width:55rem;
            font-size:20px;
            position: relative;
            z-index: 4;
        }

        h4{
            margin-top: 40px;
            margin-bottom:10px;
        }
    } 

}

@media (max-width:640px) {
    .v-proposition{
        background: url("../images/Value-Proposition-mobile.jpg") no-repeat center center;
      }
}