/* About */
.about{
    position: relative;
}
.about-info{
    /* width: 80%; */
    position: relative;
    top: -80px;
    margin: auto;
    background: $primary;
    color: $white;
    padding: 60px;
    text-align: center;
    font-size:18px;
    line-height:28px;
    font-weight: 300;
}

@media (max-width:640px) {
    .about{
        padding:15px 0;
    }
    .about-info{
        top: 0px;
        padding:30px;
        text-align: center;
        font-size:16px;
        font-weight: 300;
    }
}