.outerlink{
    text-decoration:none;
    color: $white;
    display: inline-block;
    padding:8px 16px;
    border: 1px solid $white;
    border-radius:6px;

    &:hover{
        color:$gray80;
        text-decoration:none;
        background: $white;
    }
}
.letstalk-button {
    color: $white;
    background: #ff497c;
    border:2px solid #ff497c;
    border-radius: 10px;
    display: inline-block;
    padding:20px 40px;
    font-size:18px;
    font-weight:300;  
    text-decoration: none;

    &:hover{
        text-decoration: none;
        background: $white;
        color: $mainTextColor;
    }
}

.btn-primary {
    position: relative;
    color: $white;
    background: $primary;
    border: 1px solid $primary;
    border-radius:6px;
    padding:8px 16px;
    
    &:hover {
        text-decoration: none;
        background: none;
        color: $gray80;
        border: 1px solid $primary;
    }
}