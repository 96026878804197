/* engage-model */
// .engage-model{
//     background: #1f2732;
//     background-image:url(../images/texture-process.png);
//     color: #FFF;
// }

.engagment-model-type{
   
    border: 1px solid $gray10;
    padding: 4px;
    background: $white;
    border-radius:2px;

    box-shadow: 0 0 3px rgba(0,0,0, .2);

    &:hover{
        border:5px solid $primary;
        padding: 0;
    }
    p{
        font-size: 18px;
        text-align: center;
        padding: 30px;
    
    }
}

@media (max-width:575px) {
    .engagment-model-type{
        margin:15px 0px;

        p{
            font-size:16px;
            padding:15px;
        
        }
    }
}