.blog-thubm{
    background:$gray10;
}

.bred-nav{
    padding: 20px 0;
    background: $white;
    font-size: 12px;
    border-bottom: 1px solid #ededed ;
    -webkit-box-shadow: 0px 6px 10px -16px rgba(143,143,143,1);
    -moz-box-shadow: 0px 6px 10px -16px rgba(143,143,143,1);
    box-shadow: 0px 6px 10px -16px rgba(143,143,143,1);


    a{
        color:$gray80;

        &:hover{
            color:$primary;
        }
    }

    span{
        padding: 0 10px;
    }
}
