.container-fluid{
    padding: 0 !important;
}

/*Section Spacing*/
.engagment,.offering,.technologies,.valueproposition,.works,footer,.blog-section,.blog-thubm{
    padding:$sectionSpacingForDesktop 0;
}

@media (max-width:640px) {
   .engagment,.offering,.technologies,.valueproposition,.works,footer{
    padding:$sectionSpacingForMoile 15px;
} 
}

/*:::::: Intro banner ::::: */
.intro-info{
    animation: movingBottomToTop 1.5s linear;
    position: relative;
}
  
@keyframes movingBottomToTop {
    0% {
    bottom: -60px;
    opacity: 0;
    }
    100% {
    bottom: 0px;
    opacity: 1;
    }
}
  
/*:::::: Engagement model ::::: */
.engagment-model-type {
    position: relative;
    &:before, &:after {
        content: "";
        height: calc(100% + 10px);
        width: calc(100% + 10px);
        left: -5px;
        position: absolute;
        top: -5px;
        transform: scale(0);
        transition: all 0.5s ease-in-out 0s;
        z-index: 3;
    }
    &:before {
        border-bottom: 5px solid $primary;
        border-left: 5px solid $primary;
        transform-origin: 0 100% 0;
      }
    &:after {
        border-right: 5px solid $primary;
        border-top: 5px solid $primary;
        transform-origin: 100% 0 0;
    }
    &:hover {
        border: 5px solid rgba(0, 160, 80, 0);
        padding: 0;
        &:before, &:after {
            transform: scale(1);
        }
    }
}

/*:::::: Offering ::::: */
.expertise-panel {
    transition:0.5s;
    &:hover {
        transform: translateY(-10px);
    }
  }

  .offering .services .dev-services {
    transition: all 0.2s ease-in;
    transform: scale(1);   
    position: relative;
    &:hover {
      transform: scale(1.05);
      z-index: 1;
    }
  }

  /*:::::: Our Value Proposition ::::: */

  .v-proposition {
    background-attachment: fixed !important;
  }

 .citation-section{
    color:$mainTextColor;
    font-size:12px;
    font-weight: 300;
    padding-bottom: 20px;
  }
  .citation-section a{
    color:$mainTextColor;
  }