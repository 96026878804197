.scroll-top-arrow{
    background:#878787;
    width:32px;
    height:32px;
    line-height:32px;
    border-radius:50%;
    text-align:center;
    position:fixed;
    right:10px;
    bottom:10px;
    z-index: 1000;
    display:none;
}
.scroll-top-arrow.show{
    display:inline;
}
.scroll-top-arrow img{
    width:12px;
}

@media (max-width: 991px) {
    .scroll-top-arrow {
     display: none;
    }
}